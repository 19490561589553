.highlight {
  background-color: #e7e7ff;
}
#live-indicators {
  font-size: 16pt;
}

#live-indicators div {
  min-width: 100px;
  text-align: center;
}
.user-link {
  display: block;
  color: #0d6efd !important;
}
