#dashboard {
  display: flex;
  flex: 1;
  flex-direction: row;
}

#sidebar-div {
  padding: 1rem;
}

#dashboard-page {
  padding: 10px;
  flex: 4;
  overflow: scroll;
}

.table-scroll {
  overflow: scroll;
}
.table-hover {
  cursor: pointer;
}
