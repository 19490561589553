.active a {
  color: white;
}

#logout-wrapper {
  padding-left: 10px;
  margin-top: 10px;
}
.sidebar-wrap {
  width: 280px;
}

.sidebar-wrap-collapsed {
  width: 35px;
}

.close-icon {
  margin-right: 10px;
}
